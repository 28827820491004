/*eslint-disable*/
import React from 'react';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
// react components for routing our app without refresh
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@mdi/react';
import { mdiCrown } from '@mdi/js';
// @material-ui/icons
import Cloud from '@material-ui/icons/Cloud';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import ForumIcon from '@material-ui/icons/Forum';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import ChangeLocale from '../ChangeLocale';

// core components
// import { Button } from '@jackywxd/shared-material-ui';
import Button from 'components/CustomButtons';

import headerLinksStyle from 'assets/jss/material-kit-react/components/headerLinksStyle';
import { selectCurrentUser } from '@allconnect/store/lib/user';
import { selectTheme } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import { setTheme } from '@allconnect/store/lib/appStatus/appStatus.actions';
import CustomDropdown from '../CustomDropdown/CustomDropdown';

const useStyles = makeStyles(headerLinksStyle);

const HeaderLinks = ({ currentUser, currentTheme, setTheme }) => {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      {/* {(!currentUser ||
        currentUser.type === 'free' ||
        currentUser.status !== 'subscribed') && (
          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              target="_blank"
              className={classes.navLink}
              onClick={() => {
                open('https://heidi.cloud/hi-vpn');
              }}
            >
              <Icon
                path={mdiCrown}
                title="User Profile"
                size={1}
                className={classes.icons}
              />
              <FormattedMessage id="nav.upgrade" defaultMessage="Subscribe" />
            </Button>
          </ListItem>
        )} */}
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={
            <FormattedMessage id="nav.server" defaultMessage="VPN Cloud" />
          }
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          buttonIcon={() => <Cloud className={classes.icons} />}
          dropdownList={[
            <Button
              color="transparent"
              target="_blank"
              onClick={() => {
                navigate('/free-access');
              }}
            >
              <VpnKeyIcon className={classes.icons} />{' '}
              <FormattedMessage
                id="nav.freeaccess"
                defaultMessage="Free Access"
              />
            </Button>,
            <Button
              color="transparent"
              target="_blank"
              onClick={() => {
                navigate('/servers');
              }}
            >
              <Cloud className={classes.icons} />
              <FormattedMessage id="nav.server" defaultMessage="VPN Cloud" />
            </Button>,
          ]}
        />
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          target="_blank"
          onClick={() => {
            navigate('/help');
          }}
        >
          <HelpIcon className={classes.icons} />{' '}
          <FormattedMessage id="nav.help" defaultMessage="Help" />
        </Button>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
          onClick={() => {
            currentUser ? navigate('/app/home') : navigate('/login');
          }}
        >
          <AccountCircleIcon className={classes.icons} />
          {currentUser ? (
            <FormattedMessage id="nav.myaccount" defaultMessage="Account" />
          ) : (
            <FormattedMessage id="nav.login" defaultMessage="Log In" />
          )}
        </Button>
      </ListItem> */}
      {/* {!currentUser ? (
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
            onClick={() => {
              navigate('/register');
            }}
          >
            <HowToRegIcon className={classes.icons} />{' '}
            <FormattedMessage id="nav.register" defaultMessage="Register" />
          </Button>
        </ListItem>
      ) : null} */}
      <Tooltip
        title={
          <FormattedMessage
            id="nav.toggleTheme"
            defaultMessage="Toggle light/dark theme"
          />
        }
      >
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
            onClick={() => {
              setTheme(currentTheme === 'light' ? 'dark' : 'light');
            }}
          >
            {currentTheme === 'light' ? (
              <Brightness4Icon className={classes.icons} />
            ) : (
              <Brightness7Icon className={classes.icons} />
            )}
          </Button>
        </ListItem>
      </Tooltip>

      <ListItem className={classes.listItem}>
        <ChangeLocale />
      </ListItem>
    </List>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentTheme: selectTheme,
});

const mapDispatchToProps = dispatch => ({
  setTheme: theme => dispatch(setTheme(theme)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);
