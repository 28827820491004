import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import CookieConsent from 'react-cookie-consent';

const ConsentBar = () => (
  <>
    <CookieConsent
      style={{ flexDirection: 'row-reverse', backgroundColor: '#666' }}
      location="bottom"
      buttonText={<FormattedMessage id="button.ok" />}
      overlay
      cookieName="gatsby-gdpr-google-analytics"
    >
      <FormattedMessage
        id="consent.message"
        defaultMessage="This site uses cookies to provide you with a great user experience. By using AllConnect, you accept our use of cookies."
      />
    </CookieConsent>
  </>
);

export default ConsentBar;
