import { container, primaryColor } from 'assets/jss/material-kit-react.jsx';

const footerStyle = theme => {
  const {
    palette: { type, text, background, common },
  } = theme;
  return {
    block: {
      color: text.primary,
      padding: '0.9375rem',
      fontWeight: '500',
      fontSize: '12px',
      textTransform: 'uppercase',
      borderRadius: '3px',
      textDecoration: 'none',
      position: 'relative',
      display: 'block',
    },
    left: {
      padding: '15px 0',
      margin: '0',
      float: 'left!important',
      display: 'block',
    },
    right: {
      padding: '15px 0',
      margin: '0',
      float: 'right!important',
      display: 'block',
    },
    footer: {
      color: 'black',
      padding: '0.9375rem 0',
      textAlign: 'center',
      display: 'flex',
      zIndex: '2',
      position: 'relative',
    },
    a: {
      color: '0000',
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
    footerWhiteFont: {
      '&,&:hover,&:focus': {
        color: '#FFFFFF',
      },
    },
    container,
    list: {
      marginBottom: '0',
      padding: '0',
      marginTop: '0',
    },
    inlineBlock: {
      color: text.primary,
      display: 'inline-block',
      padding: '0px',
      width: 'auto',
      fontWeight: '500',
      fontSize: '12px',
    },
    icon: {
      width: '18px',
      height: '18px',
      position: 'relative',
      top: '3px',
      color: 'red',
    },
  };
};
export default footerStyle;
