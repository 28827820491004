import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  selectAppError,
  selectAppLoading,
  selectAppMessage,
  selectLang,
} from '@allconnect/store/lib/appStatus/appStatus.selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import CookieConsent from 'react-cookie-consent';

import SEO from '../seo';

import Head from 'components/Head';
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';
import Footer from 'components/Footer/Footer';
import ProgressBar from 'components/ProgressBar';
import Notifications from 'components/Notifications';
import ConsentBar from 'components/ConsentBar';

const Layout = ({
  children,
  loading,
  error,
  message,
  lang,
}: {
  children: React.ReactNode;
  loading: boolean;
  error: string;
  message: string;
  lang: string;
}) => {
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setNotification({ error: error });
      }, 1000);
    }
    if (message) {
      setTimeout(() => {
        setNotification({ info: message });
      }, 1000);
    }
  }, [loading, error, message]);

  useEffect(() => {
    if (loading) setNotification(null);
  }, [loading]);

  return (
    <>
      <Head lang={lang.substring(0, 2)} />
      <Header
        color="transparent"
        // routes={dashboardRoutes}
        brand="AllConnect"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: 'white',
        }}
      />
      <ProgressBar open={loading} />
      {notification && <Notifications notification={notification} />}
      {children}
      <ConsentBar />
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: selectAppLoading,
  error: selectAppError,
  message: selectAppMessage,
  lang: selectLang,
});

export default connect(mapStateToProps, null)(Layout);
