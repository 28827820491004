import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { changeLocale } from 'gatsby-plugin-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectLang } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import { setLang } from '@allconnect/store/lib/appStatus/appStatus.actions';
import { InputBase } from '@material-ui/core';

const languageName = {
  en: 'English',
  'zh-TW': '中文',
};

const useStyles = makeStyles(
  theme => ({
    formControl: {
      color: 'inherit',
      fontSize: '1rem',
      margin: theme.spacing(1.5),
      minWidth: 100,
    },
    root: {
      // margin: theme.spacing(1),
      padding: '0',
      fontSize: '1rem',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'MuiNativeSelect' }
);
const Language = ({ currentLang, setLang }) => {
  const classes = useStyles();

  const lang = currentLang;

  const handleChange = event => {
    const lang = event.target.value;
    setLang(lang);
    changeLocale(lang, '/');
  };
  return (
    <>
      <FormControl className={classes.formControl}>
        <NativeSelect
          classes={{ select: classes.root }}
          IconComponent={() => null}
          value={lang}
          input={
            <InputBase
              style={{
                color: 'inherit',
                fontSize: '0.9rem',
              }}
            />
          }
          onChange={handleChange}
        >
          {Object.keys(languageName).map(lang => (
            <option
              key={lang}
              value={lang}
              style={{
                color: 'inherit',
                fontSize: '0.9rem',
              }}
            >
              {languageName[lang]}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentLang: selectLang,
});

const mapDispatchToProps = dispatch => ({
  setLang: language => dispatch(setLang(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Language);
