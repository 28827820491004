/*eslint-disable*/
import React from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';

// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite';

import footerStyle from 'assets/jss/material-kit-react/components/footerStyle';

const useStyles = makeStyles(footerStyle);

function Footer({ ...props }) {
  const classes = useStyles();

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            {/* <ListItem className={classes.inlineBlock}>
              <Link className={classes.block} to="/about-us">
                About Us
              </Link>
            </ListItem> */}
            <ListItem className={classes.inlineBlock}>
              <Link className={classes.block} to="/terms-of-use">
                <FormattedMessage
                  id="footer.termofuse"
                  defaultMessage="Terms of Use"
                />
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link className={classes.block} to="/privacy-policy">
                <FormattedMessage
                  id="footer.privacy"
                  defaultMessage="Privacy Policy"
                />
              </Link>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <div className={classes.block}>
                &copy; 2019-{new Date().getFullYear()}{' '}
                <Favorite className={classes.icon} /> Heidi Technology.
              </div>
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};

export default Footer;
