import React from 'react';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Logo = ({ data }) => {
  return (
    <div
      style={{
        height: '3rem',
        width: '3rem',
        maxWidth: 'none',
        margin: '0rem',
      }}
    >
      <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="" />
    </div>
  );
};
export default Logo;
